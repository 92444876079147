import React from 'react';
import { Layout, Typography, Button, Space } from 'antd';
import { LinkedinOutlined, GithubOutlined, MailOutlined } from '@ant-design/icons';
import { Analytics } from "@vercel/analytics/react"


const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography;

function App() {
  const currentYear = new Date().getFullYear();

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#282c34', color: 'white' }}>
      <Header style={{ backgroundColor: '#282c34', textAlign: 'center' }}>
        <Title style={{ color: 'white' }}>Coming Soon!</Title>
      </Header>
      <Content style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
        <Paragraph style={{ color: 'white', fontSize: '1.5rem' }}>
          My website is under construction. Stay tuned for something amazing!
        </Paragraph>
        <Space size="large" style={{ marginBottom: '2rem' }}>
          <Button type="link" href="https://www.linkedin.com/in/kevin-joseph-usa" target="_blank" icon={<LinkedinOutlined style={{fontSize: '3rem'}}/>} style={{ color: 'white', fontSize: '3rem' }} />
          <Button type="link" href="https://github.com/lkevin2018" target="_blank" icon={<GithubOutlined style={{fontSize: '3rem'}}/>} style={{ color: 'white', fontSize: '3rem' }} />
        </Space>
        <Button type="primary" href="mailto:kevin@thekevinjoseph.com" icon={<MailOutlined />} style={{ backgroundColor: 'white', color: '#282c34', borderColor: 'white' }}>
          Contact Me!
        </Button>
      </Content>
      <Footer style={{ backgroundColor: '#282c34', textAlign: 'center', color: 'white' }}>
        ©{currentYear} Made with love by Kevin Joseph. All rights reserved.
      </Footer>
    </Layout>
  );
}

export default App;
